import './bootstrap';
import '../css/app.css';

import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/inertia-vue3';
import {InertiaProgress} from '@inertiajs/progress';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'


const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, app, props, plugin}) {
        return createApp({
            render: () => h(app, props),
            mounted() {
                // preserving state when going back (for Toasts)
                window.addEventListener('popstate', () => {
                    this.$inertia.reload({preserveScroll: true, preserveState: true})
                });
            }
        })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(PerfectScrollbar)
            .mount(el);
    },
});

InertiaProgress.init({color: '#4B5563'});


